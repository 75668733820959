<template>
  <div class="document">
    <p class="font-weight-bold text-align">中国平安财产保险股份有限公司</p>
    <p class="font-weight-bold text-align">平安产险特定危重型传染病保险（互联网版）条款</p>
    <p class="font-weight-bold text-align">注册号：C00001732612021120909223</p>
    <p class="font-weight-bold text-align">总则</p>
    <p>&#12288;&#12288;<span>第一条</span> 本保险合同由保险条款、投保单、保险单、保险凭证以及批单等组成。凡涉及本保险合同的约定，均应采用书面形式。</p>
    <p>&#12288;&#12288;<span>第二条</span> 本保险合同的被保险人应为身体健康、能正常工作或正常生活的自然人。</p>
    <p>&#12288;&#12288;<span>第三条</span> 本保险合同的投保人应为具有完全民事行为能力的被保险人本人、对被保险人有保险利益的其他人。</p>
    <p>&#12288;&#12288;<span>若被保险人为无民事行为能力人，投保人须符合保险法规定。凡不符合保险法规定的投保申请，保险人不予承保。</span></p>
    <p>&#12288;&#12288;<span>第四条</span> 订立本保险合同时，被保险人或投保人可指定一人或数人为身故保险金受益人。身故保险金受益人为数人时，应确定其受益顺序和受益份额；<span>未确定受益份额的，各身故保险金受益人按照相等份额享有受益权。投保人指定受益人时须经被保险人同意。</span></p>
    <p>&#12288;&#12288;被保险人死亡后，有下列情形之一的，保险金作为被保险人的遗产，由保险人依照有关法律法规规定履行给付保险金的义务：</p>
    <p>&#12288;&#12288;1.没有指定受益人，或者受益人指定不明无法确定的；</p>
    <p>&#12288;&#12288;2.受益人先于被保险人死亡，没有其他受益人的；</p>
    <p>&#12288;&#12288;3.受益人依法丧失受益权或者放弃受益权，没有其他受益人的。</p>
    <p>&#12288;&#12288;受益人与被保险人在同一事件中死亡，且不能确定死亡先后顺序的，推定受益人死亡在先。</p>
    <p>&#12288;&#12288;被保险人或投保人可以变更身故保险金受益人，但需书面通知保险人，由保险人在本保险合同上批注。<span>对因身故保险金受益人变更发生的法律纠纷，保险人不承担任何责任。</span></p>
    <p>&#12288;&#12288;投保人指定或变更身故保险金受益人的，应经被保险人书面同意。被保险人为无民事行为能力人或限制民事行为能力人的，应由其监护人指定或变更身故保险金受益人。</p>
    <p>&#12288;&#12288;除另有约定外，本合同特定传染病危重型疾病保险金的受益人为被保险人本人。</p>
    <p>&#12288;&#12288;<span>第五条</span> 本保险合同承保的特定危重型传染病可为法定传染病中的一种或多种，具体由投保人和保险人双方约定，并在保险单中载明。</p>
    <p>&#12288;&#12288;保险单中未载明特定危重型传染病类型的，则指在《中华人民共和国传染病防治法》中所列明的法定传染病，该种疾病的认定以国家卫生部公布的关于该种疾病的最新定义为准。</p>
    <p>&#12288;&#12288;<span>第六条 本保险合同仅限于互联网渠道销售。</span></p>
    <p class="font-weight-bold text-align">&#12288;&#12288;保险责任</p>
    <p>&#12288;&#12288;<span>第七条</span> 本保险合同的保险责任分为“必选责任”及“可选责任”。<span>投保人在已选择投保“必选责任”的前提下，可以选择投保“可选责任”，若投保人未投保“必选责任”，不得单独投保“可选责任”。保险责任由投保人在投保时与保险人协商确定，并在保险合同中载明。</span></p>
    <p>&#12288;&#12288;<span>第八条</span> 保险期间内，保险人按照约定可承担下列保险责任：</p>
    <p>&#12288;&#12288;<span>（一）必选责任：特定传染病危重型疾病责任</span></p>
    <p>&#12288;&#12288;自保险期间开始且保险单载明的等待期满之日起，至保险期间终止之日止，被保险人经符合本保险合同释义的医院（以下简称“释义医院”）确诊罹患保险单载明类型的传染病，且在保险期间内因该传染病达到国家卫生健康管理部门或其他权威机构规定的危重型疾病的（<span>如对危重型疾病另有约定的，以保险单载明为准</span>），保险人按保险单载明的特定传染病危重型疾病保险金额给付特定传染病危重型疾病保险金，<span>对该被保险人的本项保险责任终止。</span></p>
    <p>&#12288;&#12288;<span>（二）可选责任：特定传染病身故责任</span></p>
    <p>&#12288;&#12288;自保险期间开始且保险单载明的等待期满之日起，至保险期间终止之日止，被保险人经符合本保险合同释义的医院（以下简称“释义医院”）确诊罹患保险单载明类型的传染病，且在保险期间内因该传染病导致身故的，保险人按保险单载明的特定传染病身故保险金额给付特定传染病身故保险金，<span>对该被保险人的本项保险责任终止。</span></p>
    <div class="font-weight-bold">
      <p class="font-weight-bold text-align">&#12288;&#12288;责任免除</p>
      <p>&#12288;&#12288;第九条 下列情形下，保险人不承担给付保险金责任：</p>
      <p>&#12288;&#12288;（一）被保险人在保险期间开始前以及首次投保保险期间开始后保险单载明等待期内：</p>
      <p>&#12288;&#12288;1．确诊罹患本保险合同约定的特定传染病；</p>
      <p>&#12288;&#12288;2．疑似罹患本保险合同约定的特定传染病；</p>
      <p>&#12288;&#12288;3．因与本保险合同约定的特定传染病病人或疑似病人密切接触而被隔离的；</p>
      <p>&#12288;&#12288;（二）投保人、被保险人的故意行为；</p>
      <p>&#12288;&#12288;（三）被保险人患有遗传性疾病、先天性疾病、先天性畸形、变形或染色体异常（以世界卫生组织颁布的《疾病和有关健康问题的国际统计分类（ICD-10）》为准）；</p>
      <p>&#12288;&#12288;（四）被保险人主动吸食或注射毒品的；</p>
      <p>&#12288;&#12288;（五）核爆炸、核辐射或者核污染；</p>
      <p>&#12288;&#12288;（六）被保险人未经释义医院确诊感染法定传染病的；</p>
      <p>&#12288;&#12288;（七）确诊罹患非本保险合同约定的特定传染病；</p>
      <p>&#12288;&#12288;（八）被保险人未遵医嘱服用、涂用、注射药物；</p>
    </div>
    <p class="font-weight-bold text-align">&#12288;&#12288;保险金额</p>
    <p>&#12288;&#12288;<span>第十条 保险金额是保险人承担给付保险金责任的最高限额。</span>本保险合同的特定传染病危重型疾病保险金额以及特定传染病身故保险金额由投保人和保险人双方约定，并在保险单中载明。</p>

    <p class="font-weight-bold text-align">&#12288;&#12288;保险期间与续保</p>
    <p>&#12288;&#12288;<span>第十一条</span> 本保险合同保险期间由投保人和保险人双方约定，并在保险单中载明，<span>且最长不得超过一年。</span></p>
    <p>&#12288;&#12288;<span>第十二条 本保险产品遵循不保证续保条款：本产品保险期间不超过一年。</span>保险期间届满，投保人需要重新向保险人申请投保本产品，经保险人同意，交纳保险费，获得新的保险合同。</p>
    <div class="font-weight-bold">
      <p>&#12288;&#12288;若发生下列情形之一的，本合同不再接受续保：</p>
      <p>&#12288;&#12288;（一）本产品统一停售；</p>
      <p>&#12288;&#12288;（二）被保险人身故；</p>
      <p>&#12288;&#12288;（三）投保人对于保险人就投保人或者被保险人的有关情况提出的询问未履行如实告知义务，足以影响保险人决定是否同意承保，保险人已经解除保险合同的；</p>
      <p>&#12288;&#12288;（四）投保人未如实告知,被保险人不符合投保条件或存在欺诈情形的；</p>
    </div>
    <p class="font-weight-bold text-align">&#12288;&#12288;缴费方式与宽限期</p>
    <p>&#12288;&#12288;<span>第十三条</span> 投保人可以选择一次性全额支付保险费，也可以根据合同约定分期支付保险费。</p>
    <p>&#12288;&#12288;若投保人选择一次性支付保险费的，投保人应当在投保时一次性交清保险费。</p>
    <p>&#12288;&#12288;若投保人选择分月支付保险费的，在交纳首月保险费后，投保人应当在每个保险费约定支付日交纳其余各月对应月份的保险费。</p>
    <p>&#12288;&#12288;<span>第十四条</span> 若投保人分期支付保险费，在支付首期保险费后未按约定在应付之日交纳保险费，保险人允许投保人在催告之日起三十日（含第三十日）内补交保险费，如果被保险人在此三十日内发生保险事故，保险人仍按照合同约定给付保险金，<span>但有权从给付的保险金中扣除投保人欠缴的保险费。</span></p>
    <p>&#12288;&#12288;<span>若投保人在保险人催告之日起三十日（含第三十日）内未补交保险费，则本合同自当期保费的应付之日起终止，对于保险合同终止后发生的保险事故，保险人不承担保险责任。</span></p>

    <p class="font-weight-bold text-align">&#12288;&#12288;保险人义务</p>
    <p>&#12288;&#12288;<span>第十五条</span> 订立本保险合同时，采用保险人提供的格式条款的，保险人向投保人提供的投保单应当附格式条款，保险人应当向投保人说明本保险合同的内容。对保险合同中免除保险人责任的条款，保险人在订立合同时应当在投保单、保险单或者其他保险凭证上做出足以引起投保人注意的提示，并对该条款的内容以书面或者口头形式向投保人做出明确说明。未作提示或者明确说明的，该条款不产生效力。</p>
    <p>&#12288;&#12288;<span>第十六条</span> 本保险合同成立后，保险人应当及时向投保人签发保险单或其他保险凭证。</p>
    <p>&#12288;&#12288;<span>第十七条</span> 保险人按照本保险合同的约定，认为被保险人提供的有关索赔的证明和资料不完整的，应当及时一次性通知投保人、被保险人补充提供。</p>
    <p>&#12288;&#12288;<span>第十八条</span> 保险人收到被保险人的给付保险金的请求后，应当及时作出是否属于保险责任的核定；情形复杂的，保险人将在确定是否属于保险责任的基本材料收集齐全后，尽快做出核定。</p>
    <p>&#12288;&#12288;保险人应当将核定结果通知被保险人；对属于保险责任的，在与被保险人达成给付保险金的协议后十日内，履行赔偿保险金义务。保险合同对给付保险金的期限有约定的，保险人应当按照约定履行给付保险金的义务。保险人依照前款约定作出核定后，对不属于保险责任的，应当自作出核定之日起三日内向被保险人发出拒绝给付保险金通知书，并说明理由。</p>
    <p>&#12288;&#12288;<span>第十九条</span> 保险人自收到给付保险金的请求和有关证明、资料之日起六十日内，对其给付的数额不能确定的，应当根据已有证明和资料可以确定的数额先予支付；保险人最终确定给付的数额后，应当支付相应的差额。</p>

    <p class="font-weight-bold text-align">&#12288;&#12288;投保人、被保险人义务</p>
    <p>&#12288;&#12288;<span>第二十条</span> 订立保险合同，保险人就被保险人的有关情况提出询问的，投保人应当如实告知。</p>
    <p>&#12288;&#12288;<span>投保人故意或者因重大过失未履行前款规定的义务，足以影响保险人决定是否同意承保或者提高保险费率的，保险人有权解除本保险合同。</span></p>
    <p>&#12288;&#12288;前款规定的合同解除权，自保险人知道有解除事由之日起，超过三十日不行使而消灭。自合同成立之日起超过二年的，保险人不得解除合同；发生保险事故的，保险人应当承担给付保险金责任。</p>
    <p>&#12288;&#12288;<span>投保人故意不履行如实告知义务的，保险人对于合同解除前发生的保险事故，不承担给付保险金责任，并不退还保险费。</span></p>
    <p>&#12288;&#12288;<span>投保人因重大过失未履行如实告知义务，对保险事故的发生有严重影响的，保险人对于合同解除前发生的保险事故，不承担给付保险金责任，但应当退还保险费。</span></p>
    <p>&#12288;&#12288;保险人在合同订立时已经知道投保人未如实告知的情况的，保险人不得解除合同；发生保险事故的，保险人应当承担给付保险金责任。</p>
    <p>&#12288;&#12288;<span>第二十一条</span> 投保人、被保险人或者保险金受益人知道保险事故发生后，应当及时通知保险人。<span>故意或者因重大过失未及时通知，致使保险事故的性质、原因、损失程度等难以确定的，保险人对无法确定的部分，不承担给付保险金责任，</span>但保险人通过其他途径已经及时知道或者应当及时知道保险事故发生的除外。</p>
    <p>&#12288;&#12288;上述约定，不包括因不可抗力而导致的迟延。</p>

    <p class="font-weight-bold text-align">&#12288;&#12288;保险金申请与给付</p>
    <p>&#12288;&#12288;<span>第二十二条</span> 保险金申请人向保险人申请给付保险金时，应提交以下材料。保险金申请人因特殊原因不能提供以下材料的，应提供其他合法有效的材料。<span>如保险人对保险金申请材料存疑，有权要求被保险人在保险人指定或认可的医疗机构进行复检确认；被保险人应予配合。保险金申请人未能提供有关材料或被保险人不予配合，导致保险人无法核实该申请的真实性的，保险人对无法核实部分不承担给付保险金的责任。</span></p>
    <p>&#12288;&#12288;（一）保险金给付申请书；</p>
    <p>&#12288;&#12288;（二）保单号或其他有效保险凭证；</p>
    <p>&#12288;&#12288;（三）被保险人身份证明、保险金申请人身份证明；</p>
    <p>&#12288;&#12288;（四）由释义医院出具的对被保险人的特定传染病、特定传染病危重型疾病诊断证明书以及由医院出具的与诊断证明书相关的病历、病理显微镜检查、血液检验及其他科学方法检验报告；</p>
    <p>&#12288;&#12288;（五）被保险人身故的，还应提供公安部门或医疗机构出具的被保险人死亡证明书；如本保险合同要求的死亡证明可证明死亡原因的，可用死亡证明；<span>否则，保险金申请人应提供司法鉴定机构或保险人认可的机构出具的死因鉴定报告；</span></p>
    <p>&#12288;&#12288;（六）保险金申请人所能提供的与确认保险事故的性质、原因、损失程度等有关的其他证明和资料；</p>
    <p>&#12288;&#12288;（七）若保险金申请人委托他人申请的，还应提供授权委托书原件，委托人和受托人的身份证明等相关证明文件。 </p>
    <p>&#12288;&#12288;（八）保险金作为被保险人遗产时，须提供可证明所有直系亲属合法继承权的相关权利文件，及授权支付至保险金申请人的授权书；</p>
    <p>&#12288;&#12288;<span>被保险人经基本医疗保险或公费医疗报销后又通过其它途径获得了部分医疗费用的补偿并无法提供医疗费用原始凭证时，需提供住院医疗费用凭证复印件等相关证明并注明已给付的比例和金额，加盖支付费用单位的印章后保险人按本保险合同约定承担剩余合理医疗费用的保险责任。</span></p>

    <p class="font-weight-bold text-align">&#12288;&#12288;争议处理和法律适用</p>
    <p>&#12288;&#12288;<span>第二十三条</span> 因履行本保险合同发生的争议，由当事人协商解决。协商不成的，提交保险单载明的仲裁机构仲裁；保险单未载明仲裁机构且争议发生后未达成仲裁协议的，依法向中华人民共和国（不包括港澳台地区法律）人民法院起诉。</p>
    <p>&#12288;&#12288;<span>第二十四条</span> 与本保险合同有关的以及履行本保险合同产生的一切争议处理适用中华人民共和国法律<span>（不包括港澳台地区法律）。</span></p>

    <p class="font-weight-bold text-align">&#12288;&#12288;其他事项</p>
    <p>&#12288;&#12288;<span>第二十五条</span> 投保人和保险人可以协商变更合同内容。</p>
    <p>&#12288;&#12288;变更保险合同的，应当由保险人在保险单或者其他保险凭证上批注或附贴批单，或者投保人和保险人订立变更的书面协议。</p>
    <p>&#12288;&#12288;<span>第二十六条</span> 在本保险合同成立后，投保人可以书面形式通知保险人解除合同，<span>但保险人已根据本保险合同约定给付保险金的除外。</span></p>
    <p>&#12288;&#12288;投保人解除本保险合同时，应提供下列证明文件和资料：</p>
    <p>&#12288;&#12288;（一）保险合同解除申请书；</p>
    <p>&#12288;&#12288;（二）保险单；</p>
    <p>&#12288;&#12288;（三）保险费交付凭证；</p>
    <p>&#12288;&#12288;（四）投保人身份证明。</p>
    <p>&#12288;&#12288;<span>投保人要求解除本合同，自保险人接到保险合同解除申请书之时起，本合同的效力终止。保险人收到上述证明文件和资料之日起三十日内退还保险单的现金价值，其计算方法如下：</span></p>
    <p>&#12288;&#12288;<span>保险单现金价值=净保费×[1－（保险期间已经过天数/保险期间天数）]，经过天数不足一天的按一天计算。</span></p>

    <p class="font-weight-bold text-align">&#12288;&#12288;释义</p>
    <p>&#12288;&#12288;【医院】指国家卫生部医院等级分类中的二级或二级以上公立医院或卫生健康委员会指定的特定传染病诊治定点医院，<span>但前述医院并不包括单独作为诊所、康复、护理、疗养、戒酒、戒毒等或相类似的医疗机构。</span>同时该医院必须具有符合有关医院管理规定设置标准的医疗设备，并且全日24小时有合格医师及护士驻院提供医疗和护理等服务。</p>
    <p>&#12288;&#12288;【遗传性疾病】指生殖细胞或受精卵的遗传物质（染色体和基因）发生突变或畸变所引起的疾病，通常具有由亲代传至后代的垂直传递的特征。</p>
    <p>&#12288;&#12288;【先天性畸形、变形或染色体异常】指被保险人出生时就具有的畸形、变形或染色体异常。先天性畸形、变形和染色体异常依照世界卫生组织《疾病和有关健康问题的国际统计分类》（ICD-10）确定。</p>
    <p>&#12288;&#12288;【毒品】指《中华人民共和国刑法》规定的鸦片、海洛因、甲基苯丙胺（冰毒）、吗啡、大麻、可卡因以及国家规定管制的其他能够使人形成瘾癖的麻醉药品和精神药品，但不包括由医生开具并遵医嘱使用的用于治疗疾病但含有毒品成分的处方药品。【政府主办补充医疗】指城乡居民大病保险、城镇职工大额医疗保险、城镇居民大额医疗保险等由政府主办对基本医疗保险进行补充的医疗保障项目，大额医疗保险在各地的具体名称会有所不同，以投保所在地政府主管部门规定的名称为准。</p>
    <p>&#12288;&#12288;【保险费约定支付日】指保险合同生效日在每月的对应日。 如果当月无对应的同一日，则以该月最后一日为对应日。 如保险合同生效日为2020年2月8日,则次月的保险费约定支付日为2020年3月8日，以此类推，则最后一个月的保险费约定支付日为2021年1月8日。</p>
    <p>&#12288;&#12288;【不可抗力】指不能预见、不能避免并不能克服的客观情况。</p>
    <p>&#12288;&#12288;【保险金申请人】指受益人或被保险人的继承人或依法享有保险金请求权的其他自然人。</p>
    <p>&#12288;&#12288;【保险人认可的机构】指有执业资格的医疗机构、公安机关、法院，其出具的包含死亡原因和时间的身故证明，须加盖有效的公章。</p>
    <p>&#12288;&#12288; 【身份证明】指政府有权机关颁发的能够证明其合法真实身份的证件或文件等，如居民身份证、按规定可使用的有效护照等。</p>
  </div>
</template>
<script>
import { Vue, Component } from "vue-property-decorator";

@Component({})
class ReceiveCritically extends Vue {
   static name = "receive-critically";
}
export default ReceiveCritically;
</script>
<style lang="scss" src="./index.scss" scoped></style>
