import BottomButton from "@/components/BottomButton";
import receiveInfectious from "@/components/documents/receiveInfectious";
import receiveCritically from "@/components/documents/receiveCritically";
import receiveDisclaimer from "@/components/documents/receiveDisclaimer";
import receiveNotice from "@/components/documents/receiveNotice";
import receiveDisclosure from "@/components/documents/receiveDisclosure";
import receivePolicy from "@/components/documents/receivePolicy";

import { registerComponent } from "@/utils";
import { Tab, Tabs } from "vant";

export const components = registerComponent({
  Tab,
  Tabs,
  BottomButton,
  receiveInfectious,
  receiveCritically,
  receiveDisclaimer,
  receiveNotice,
  receiveDisclosure,
  receivePolicy,
});

export default { components };
